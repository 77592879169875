<template>
  <base-section
    id="location"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <base-section-heading
        class="primary--text gap"
        color="transparent"
        title="STANDORTE &  KURSZEITEN"
      />

      <v-container
        fluid
        class="py-8 pr-8 pl-8"
      >
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="pl-14 pr-10 "
          >
            <h3 class="text-uppercase text-h5 primary--text">
              DYNAMO
              <p class="accent--text text-uppercase  text-h6 mb-6">
                DYNAMO KULTURHAUS, WASSERWERKSTRASSE 89, 8006 ZÜRICH
                (5. STOCK - TANZDACH) <br><br>
                <strong>DIENSTAG</strong><br>
                16:30 - 18:3<br>
                FREIES TRAINING MIT AUFSICHT (FÜR FORTGESCHRITTENE)<br><br>
                <strong>MITTWOCH</strong><br>
                16:00 - 17:00    *BEGINNER-KLASSE<br>
                17:00 - 18:00
              </p>
            </h3>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="pl-14 pr-10 "
          >
            <h3 class="text-uppercase text-h5 primary--text">
              CAMBIUM
              <p class="accent--text text-uppercase  text-h6 mb-6">
                WASSERWERKSTRASSE 21, 8006 ZÜRICH
                (EINGANG - BRÜCKENPFEILER UNTER KORNHAUSBRÜCKE)<br><br>
                <strong>MONTAG</strong> <br>
                UNTERREICHTSSTUNDEN IM CAMBIUM<br>
                18:00 - 19:30<br><br>
                <strong>DONNERSTAG</strong><br>
                18:30- 20:00<br><br>
                <strong>SAMSTAG</strong><br>
                10:30- 12:00
              </p>
            </h3>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
            class="pl-14 pr-10 "
          >
            <h3 class="text-uppercase text-h5 primary--text">
              BÜLACH
              <p class="accent--text text-uppercase  text-h6 mb-6">
                KASERNENSTRASSE 3D,<br>
                8184 BACHENBÜLACH<br><br>
                <strong>MITTWOCH</strong><br>
                13:30 - 14:30
              </p>
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="text-center">
              <base-btn
                :tile="false"
                color="tertiary"
                rounded
              >
                <router-link
                  class="black--text"
                  to="/team"
                  style="text-decoration: none"
                >
                  Partnerschulen
                </router-link>
              </base-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',
  }
</script>
<style scoped>
.v-application .text-h6{
  font-weight: 700;
}
.gap{line-height: 5rem;}
</style>
